<template>
  <div>
    <nav-bar />
    <events-hero/>
    <events/>
    <Footer />
  </div>
</template>

<script>
import EventsHero from '../components/New/Events-hero.vue';
import Events from '../components/New/Events.vue';
import Footer from "../components/New/Footer.vue";
import NavBar from "../components/New/NavBar.vue";
export default {
  components: { Footer, NavBar, Events, EventsHero },
};
</script>

<style  scoped>
</style>