<template>
  <div class="hero-bg">
    <section class="text-center">
      <h2 class="pb-5 lead">Events</h2>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.hero-bg {
  background: url("../../assets/new/Events.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 250px; 
  height: 411px;
  background-repeat: no-repeat;
  position: relative;
}

section{
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: center;
    align-items: center;
    /* padding-bottom: 2em; */
}
.lead{
  font-weight: bolder;
  font-size: 3em;
}
</style>