<template>
  <div class="container-fluid events">

    <!-- <div class="row py-5">
      <div class="col-md-12">
        <h2 class="py-4"><b>Next Event Countdown</b></h2>

        <h4 class="py-3">
          1st international Conference on Public health in Africa
        </h4>
        <p>
          02 February 2021, Addis Ababa, Ethiopia — The Africa Centres for
          Disease Control and Prevention (Africa CDC) is announcing...
        </p>
        <div class="row">
          <div class="col-md-9">
            <span>14 May 2021 | Addis Ababa, Ethiopia</span> <br />
            <small>Languages: English, French</small>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row past_events">

      <div class="col-md-12">
          <h2 class="title ">Past Events</h2>
          <hr />
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 event-image">
            <img
              src="../../assets/new/tradecommencementmeetings_smpost.png"
              class="img-responsive"
              alt=""
            />
          </div>
          <div class="col-md-6 event-text">
            <h5>Inaugural Meeting of the Dispute Settlement Body of the African
              Continental Free Trade Area (AfCFTA).
            </h5>
            <p>The African Continental Free Trade Area (AfCFTA), the world’s
              largest free-trade area, started trading on 1 January 2021 ...
              <a
                href="https://afcfta.au.int/en/events/2021-04-26/dispute-settlement-body-aims-settle-trade-disputes-between-member-states"
                target="_blank"
                >read more</a
              >
            </p>
            <span class="date">26th of April, 2021</span> <br> 
            <span class="location">Online</span> <br>
            <small class="lang">Languages: English, French</small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 event-image">
            <img
              src="../../assets/new/microsoftteams-image_32.png"
              class="img-responsive"
              alt=""
            />
          </div>
          <div class="col-md-6 event-text">
            <h5>The AfCFTA Start of Trading Ceremony Webinar</h5>
            <p>On 1st January 2021 trading under the AfCFTA will start in Africa.
              This is a historic milestone for the continent as Africa will
              really start to trade with Africa...
              <a
                target="_blank"
                href="https://afcfta.au.int/en/events/2021-01-01/afcfta-start-trading-ceremony-webinar"
                >Read more</a
              >
            </p>
            <span class="date">Jan 01, 2021</span> <br />
             <span class="location">Accra, Ghana</span><br>
            <small class="lang">Languages: English, French</small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 event-image">
            <img
              src="../../assets/new/virtual-meeting.jpg"
              class="img-responsive"
              alt=""
            />
          </div>
          <div class="col-md-6 event-text">
            <h5>Press briefing on the status of AfCFTA</h5>
            <p>Trading under the African Continental Free Trade Area started inearnest on 1 January 2021...
              <a
                target="_blank"
                href="https://afcfta.au.int/en/events/2021-01-12/press-briefing-status-afcfta"
                >Read more</a
              >
            </p>
            <span class="date">12th January, 2021</span> <br />
            <span class="location">Online</span> <br>
            <small class="lang">Languages: English, French</small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 event-image">
            <img
              src="../../assets/new/entrep.jpg"
              class="img-responsive"
              alt=""
            />
          </div>
          <div class="col-md-6 event-text">
            <h5>The 5th Council of Ministers meeting</h5>
            <p>The meeting will start with opening remarks by the Chairperson of
              the Council of Ministers responsible for Trade, Minister Ebrahim
              Patel....
              <a
                target="_blank"
                href="https://afcfta.au.int/en/events/2021-05-03/afcfta-ministers-responsible-trade"
                >Read more</a
              >
            </p>
            <span class="date">Monday 03th of May 2021</span> <br />
             <span class="location">Online</span> <br>
            <small class="lang">Languages: English, French</small>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 event-image">
            <img
              src="../../assets/new/tradecommencementmeetings_twitter.png"
              class="img-responsive"
              alt=""
            />
          </div>
          <div class="col-md-6 event-text">
            <h5>Preparatory Meetings for Commencement of Trade under AfCFTA</h5>
            <p>Preparatory Meetings for Commencement of Trade under AfCFTA...
              <a
                target="_blank"
                href="https://afcfta.au.int/en/events/2020-11-16/preparatory-meetings-commencement-trade-under-afcfta"
                >Read more</a
              >
            </p>
            <span class="date">Nov 16, 2020</span> <br />
            <span class="location">Accra, Ghana</span> <br>
            <small class="lang">Languages: English, French</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 event-image">
            <img
              src="../../assets/new/cfcta-redo.png"
              class="img-responsive"
              alt=""
            />
          </div>
          <div class="col-md-6 event-text">
            <h5>The Official hand over and Commissioning of the African
                Continental Free Trade Area (AfCFTA) Secretariat Building
            </h5>
            <p>The African Union (AU) Assembly of Heads of State and Government
              during....
              <a
                target="_blank"
                href="https://afcfta.au.int/en/events/2020-04-17/official-hand-over-and-commissioning-afcfta-scretariat-building"
                >Read more</a
              >
            </p>
            <span class="date">Monday,17th August, 2020</span> <br />
            <span class="location">Accra, Ghana</span> <br>
            <small class="lang">Languages: English, French</small>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row pt-5">
      <div class="col-md-12 text-center">
        <button class="btn view_more">View more</button>
      </div>
    </div> -->

  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.past_events{
  margin-top:100px;
}

.past_events > div:not(:last-of-type), .past_events > div:not(:first-of-type){
  margin-bottom: 40px;
}
.title{
  font-weight: bold;
  font-size: 28px;
}
.events {
  padding-bottom: 60px;
  padding-right: 12%;
  padding-left: 12%;
}
.past_events h5, .past_events small, .past_events span{
  font-weight: bold;
}
.past_events .lang{
  font-style: italic;
}

hr {
  border: 1px solid #000;
}

.img-responsive {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.view_more {
  border: 1px solid rgba(0, 0, 0, 0.7);
  outline: none;
  transition: all 0.5s;
}
.view_more:focus,
.view_more:active,
.view_more:hover {
  outline: none;
  background-color: rgb(235, 41, 41);
  color: #fff;
  border: 1px solid rgb(235, 41, 41);
}
.more {
  color: #8c8c8c;
}
@media screen and (max-width: 1100px) {
  .events{
      padding-right:5%;
      padding-left: 5%;
  }
}
@media screen and (max-width: 768px) {
  .events{
      padding-bottom: 30px;
  }
  .past_events{
    margin-top:70px;
  }
  .event-image{
    margin-bottom: 1em;
  }
}
</style>